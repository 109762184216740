import {Label} from "semantic-ui-react";
import React from "react";

function ProjectStatusLabel({status}) {
    return (
        <>
            {status === 'draft' && (
                <Label as="a" color="orange" size="large" style={{borderRadius: '99px'}}>
                    Draft
                </Label>
            )}

            {status === 'on-going' && (
                <Label as="a" color="orange" size="large" style={{borderRadius: '99px'}}>
                    On-going
                </Label>
            )}

            {status === 'pending' && (
                <Label as="a" color="orange" size="large" style={{borderRadius: '99px'}}>
                    pending
                </Label>
            )}

            {status === 'completed' && (
                <Label as="a" color="green" size="large" style={{borderRadius: '99px'}}>
                    Completed
                </Label>
            )}
        </>
    )
}

export default ProjectStatusLabel;