import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";

import AdminDashLayout from "../components/AdminDashLayout";
import sendApiRequest from "../api";
import { emailRegex, phoneRegex } from "../utils";
import { fetchFlat } from "../apiCalls";

const EditClientPropertyFlat = () => {
    const { flatId } = useParams();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [flatNumber, setFlatNumber] = useState("");
    const [errors, setErrors] = useState({ email: "" });
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const [flatDetails, setFlatDetails] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(false)
        fetchFlat(flatId, localStorage.getItem('token')).then(response => {
            console.log(response)
            setName(response.data.tenant_name);
            setEmail(response.data.tenant_email);
            setAddress(response.data.tenant_address);
            setPhone(response.data.tenant_phone_number);
            setFlatNumber(response.data.flat_name);
            setFlatDetails(response.data);
        })
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            propertyId: flatDetails.property_id,
            tenantName: name,
            tenantAddress: address,
            tenantEmail: email,
            tenantPhone: phone,
            flatNumber: flatNumber,
            clientId: flatDetails.client_id,
        };

        // const formData = {
        //     tenantName: name,
        //     tenantAddress: address,
        //     tenantEmail: email,
        //     tenantPhone: phone,
        //     flatNumber: flatNumber,
        // };

        let newErrors;
        newErrors = {};

        if (formData.name !== "") {

        } else {
            newErrors.name = "Please enter the clients name";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        if (formData.email !== "") {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.email = "Please enter the clients email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        // Login request
        (async () => {
            const postData = await sendApiRequest("post", `/clients/property/flat/edit/${flatId}`, formData, localStorage.getItem('token'));
            if (postData !== null) {
                // console.log(postData);
                // any errors?
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    // const errors = postData.errors;
                    const errorMsg = [];

                    // if (postData.errors) {
                    //     errors.forEach((e) => {
                    //         errorMsg.push(e.msg);
                    //     });
                    // } else {
                    //     errorMsg.push(postData.error);
                    // }

                    errorMsg.push(postData.errors);

                    setApiErrorMessage(errorMsg);
                }

                // if we got here, no errors.
                if (postData.message === "success") {
                    setApiSuccess(true);
                    setApiSuccessMessage("Flat details updated Successfully");
                    toast("Flat details updated Successfully");
                    navigate(`/dashboard/clients/property/${flatDetails.property_id}/flat/view/${flatId}`);
                    setName('')
                    setAddress('')
                    setEmail('')
                    setPhone('')
                }
            } else {
                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                toast("Error occurred while submitting data to the API.")
                setApiErrors(true)
                setApiSuccess(false);
                setLoading(false);

                // console.log("Error occurred while submitting data to the API.");
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <AdminDashLayout>
                <div style={{paddingTop: 40}}>
                    <h2>Editing - {`Flat ${flatNumber}`}</h2>
                </div>
                <div style={{paddingTop: 30, paddingRight: 20}}>
                    <Grid>
                        <Grid.Column width={8} floated="left">
                            {apiErrors ? (
                                <Message
                                    error
                                    header="Whoops!"
                                    list={apiErrorMessage}
                                />
                            ) : null}

                            {apiSuccess ? (
                                <Message
                                    success
                                    header="Success!"
                                    content={apiSuccessMessage}
                                />
                            ) : null}
                            <Form loading={loading ? true : false}>
                                
                                <Form.Input
                                    size="medium"
                                    label="FLAT NAME/FLAT NO"
                                    placeholder="Enter Flat No."
                                    value={flatNumber}
                                    required={true}
                                    onChange={(e) => {
                                        setFlatNumber(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    size="medium"
                                    label="TENANT NAME"
                                    placeholder="Enter Tenant Name"
                                    value={name}
                                    required={true}
                                    error={errors.name ? {content: errors.name} : null}
                                    onChange={(e) => {
                                        errors.name = null;
                                        setName(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    size="medium"
                                    label="TENANT ADDRESS"
                                    placeholder="Enter Tenant Address"
                                    value={address}
                                    required={true}
                                    error={errors.address ? {content: errors.address} : null}
                                    onChange={(e) => {
                                        errors.address = null;
                                        setAddress(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    size="medium"
                                    label="TENANT EMAIL"
                                    placeholder="Enter Tenant Email"
                                    value={email}
                                    required={true}
                                    error={errors.email ? {content: errors.email} : null}
                                    onChange={(e) => {
                                        errors.email = null;
                                        setEmail(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    size="medium"
                                    label="TENANT PHONE NUMBER"
                                    placeholder="Enter Tenant Phone Number"
                                    value={phone}
                                    required={true}
                                    error={errors.phone ? {content: errors.phone} : null}
                                    onChange={(e) => {
                                        errors.phone = null;
                                        setPhone(e.target.value);
                                    }}
                                />

                                <Button
                                    size="midi"
                                    color="primary"
                                    content="Save changes"
                                    icon="plus"
                                    labelPosition="right"
                                    onClick={handleSubmit}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </AdminDashLayout>
        </>
    );
};

export default EditClientPropertyFlat;