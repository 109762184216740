import React, {useState, useRef, useEffect} from "react";
import Avatar from "react-avatar";
import {Form, Icon} from "semantic-ui-react";
import {formatRelativeTime} from "../utils";
import sendApiRequest from "../api";
import {toast} from "react-toastify";

const ChatWidgetTenantProject = ({id, messages, user, updateMessages}) => {
    const [newMessage, setNewMessage] = useState("");
    const chatRef = useRef(null);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault()
        if (newMessage.trim() !== "") {
            const newChatMessage = [{
                text: newMessage,
                sender: {
                    id: user.id, // Assuming user's ID here
                    firstname: user.firstname, // Assuming user's firstname here
                    staff_photo_uri:
                    user.staff_photo_uri, // Assuming user's image here
                },
                time: new Date().toISOString(),
            }];

            // Create a new array with the updated messages
            const updatedMessages = messages ? [...messages, ...newChatMessage] : [...newChatMessage];

            // Update the state with the new array of messages
            updateMessages(updatedMessages);

            setNewMessage("");

            // Handle sending newChatMessage to server or updating state in the parent component
            const getResponse = await sendApiRequest("post", "/tenant-projects/edit/" + id, {projectChats: updatedMessages}, localStorage.getItem('token'));
            if (getResponse.errors || getResponse.error) {
                toast('Error')
                return null;
            }

        }
    };

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <Form>
            <div className="chat-container">
                <div className="chat-messages" ref={chatRef}>
                    {messages === null ? (
                        <div className="no-messages">No messages yet</div>
                    ) : (
                        messages.map((message, index) => (
                            <div
                                key={index}
                                className={`message ${
                                    message.sender.id === user.id ? "bot-message" : "user-message"
                                }`}
                            >
                                <div className="message-content">
                                    <div style={{paddingBottom: 10}}>
                                        {message.sender.firstname},{" "}
                                        <small>{formatRelativeTime(message.time)}</small> <br/>
                                    </div>

                                    <div style={{display: "flex"}}>
                                        <div>
                                            {message.sender.id === user.id && (
                                                <>
                                                    <Avatar
                                                        name={message.sender.firstname}
                                                        size="40"
                                                        round={true}
                                                        style={{marginRight: 10}}
                                                        src={message.sender.staff_photo_uri}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {message.sender.id !== user.id && (
                                                <>
                                                    <Avatar
                                                        name={message.sender.firstname}
                                                        size="40"
                                                        round={true}
                                                        style={{marginLeft: 10}}
                                                        src={message.sender.staff_photo_uri}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        &nbsp;&nbsp;
                                        <div
                                            className={
                                                message.sender.id === user.id
                                                    ? "message-text-bot"
                                                    : "message-text-user"
                                            }
                                        >
                                            {message.text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div className="message-input">
                    <input
                        type="text"
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={handleInputChange}
                    />
                    <button onClick={handleSendMessage}>
                        <Icon name="send"/>
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default ChatWidgetTenantProject;
