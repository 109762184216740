import React, { useEffect, useState, forwardRef } from "react";
import { Button, Form, Grid, Breadcrumb, Message, Checkbox } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { FileMosaic, Dropzone } from "@files-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AdminDashLayout from "../components/AdminDashLayout";
import EditProjectMilestoneComponent from "../components/EditProjectMilestoneComponent";
import sendApiRequest from "../api";
import { uploadFiles } from "../apiCalls";
import { formatToYYYYMMDD, requiresMultipleVisitOptions } from "../utils";
import { fetchTenantProject } from "../apiCalls";
import AuthService from "../auth"

const EditTenantProject = () => {
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const { id, flatId } = useParams();
  const [project, setProject] = useState("");
  const [files, setFiles] = React.useState([]);
  const [clientsOptions, setClients] = useState([]);
  const [staffOptions, setStaffs] = useState([]);
  const [
    projectRequiresMultipleSiteVisits,
    setprojectRequiresMultipleSiteVisits,
  ] = useState("no");

  const [milestones, setMilestones] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      assignedTo: [],
      visitName: "",
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientName, setClientName] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiErrors, setApiErrors] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");

  const [enableProjectReport, setEnableProjectReport] = useState();
  const [enableProjectSchematics, setEnableProjectSchematics] = useState();
  const [enableDisclaimer, setEnableDisclaimer] = useState();
  const [enableLandlordRecord, setEnableLandlordRecord] = useState();

  useEffect(() => {
    sendApiRequest(
      "get",
    //   "/projects/fetch?id=" + id,
      `/tenant-projects/fetch?id=${id}`,
      localStorage.getItem("token")
    ).then((res) => {
      setProject(res.data[0]);
      setName(res.data[0].projectName);
      setClient(res.data[0].clientId);
      setClientAddress(res.data[0].clientAddress);
      setClientName(res.data[0].clientName)
      setAssignedTo(res.data[0].assignedTo);
      setDescription(res.data[0].workRequired);

      setEnableDisclaimer(res.data[0].enableDisclaimer);
      setEnableLandlordRecord(res.data[0].enableLandlordRecord);
      setEnableProjectReport(res.data[0].enableProjectReport);
      setEnableProjectSchematics(res.data[0].enableProjectSchematics);

      const formattedStartDate = res.data[0].projectStartDate ? new Date(res.data[0].projectStartDate) : new Date();
      const formattedEndDate = res.data[0].projectEndDate ? new Date(res.data[0].projectEndDate) : new Date();

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);

      if (res.data[0].projectMilestones?.length > 0) {
        setprojectRequiresMultipleSiteVisits("yes");
        setMilestones(res.data[0].projectMilestonesFree);
      }
    }, [id]);

    sendApiRequest("get", "/clients", localStorage.getItem("token")).then(
      (res) => {
        // Extract the "data" array from the API response
        const responseData = res.data;

        // Transform the data to the desired format
        const transformedData = responseData.map((item) => ({
          key: item.id,
          text: item.clientName,
          value: item.id,
        }));

        setClients(transformedData);
      }
    );

    sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
      (res) => {
        const staffData = res.data.filter((worker) => worker.role === 2 || worker.role === 1).map((workers) => ({
          key: workers.id,
          text: workers.firstname,
          value: workers.id,
        }));

        setStaffs(staffData);
      }
    );
  }, [id]);

  const updateFiles = (incomingFiles) => {
    //do something with the files
    setFiles(incomingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const handleMilestonesChange = (updatedMilestones) => {
    setMilestones(updatedMilestones);
  };

  const handleSubmit = () => {
    setLoading(true);
    setApiSuccess(false);
    setApiErrors(false);

    let newErrors;
    newErrors = {};

    const formData = {
        projectName: name,
        clientId: client,
        clientAddress: clientAddress,
        clientName: clientName,
        assignedTo: assignedTo,
        workRequired: description,
        projectStartDate: formatToYYYYMMDD(startDate),
        projectEndDate: formatToYYYYMMDD(endDate),
        enableDisclaimer: enableDisclaimer,
        enableLandlordRecord: enableLandlordRecord,
        enableProjectReport: enableProjectReport,
        enableProjectSchematics: enableProjectSchematics,
    };

    // Assuming milestones is the array of objects
    // Assuming milestones is the array of objects
    if (projectRequiresMultipleSiteVisits === "yes") {
      formData.projectMilestones = milestones.map((milestone) => ({
        endDate: milestone.endDate,
        startDate: milestone.startDate,
        visitName: milestone.visitName,
        assignedTo: milestone.assignedTo.map((assigned) => assigned).filter((assigned) => assigned !== null)
      }));
    }

    // console.log(formData.projectMilestones)

    // return;

    // Login request
    (async () => {
      try {
        let schematicUri = "";

        // Construct formData here, including the photoUri if it was set
        const apiData = {
          ...formData,
        };

        if (files.length > 0) {
          // Upload files and set the photoUri
          const uploadData = await uploadFiles(files);
          schematicUri = uploadData.data[0].urls.path;
          apiData.projectSchematics = [
            schematicUri,
          ];
        }

        // Send the API request
        const postData = await sendApiRequest(
          "post",
          "/tenant-projects/edit/" + id,
          apiData,
          localStorage.getItem("token")
        );

        if (postData !== null) {
          setLoading(false);
          // Handle API response here
          if (postData.errors || postData.error) {
            // Handle errors
            setApiErrors(true);
            setApiSuccess(false);
            const errors = postData.errors || [postData.errors];
            // const errorMsg = errors.map((e) => e.msg);
            // setApiErrorMessage(errorMsg);
            toast('An error ocurred')
          } else if (postData.message === "Project updated successfully") {
            // Handle success
            setApiSuccess(true);
            toast("Project Modified Successfully");
            // Reset your form state here if needed
            // setName('')
            // setClient('')
            // setClientAddress('')
            // setAssignedTo('')
            // setDescription('')
            // setStartDate('')
            // setEndDate('')
          }
        } else {
          // Handle API request error
          setApiErrorMessage([
            "Error occurred while submitting data to the API.",
          ]);
          setApiErrors(true);
          setApiSuccess(false);
        }
      } catch (error) {
        // Handle any unexpected errors
        setApiErrorMessage([
          "Error occurred while submitting data to the API.",
        ]);
        setApiErrors(true);
        setApiSuccess(false);
        console.error(error);
      } finally {
        // Ensure setLoading is set to false
        setLoading(false);
      }
    })();


  };

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 40 }}>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/projects"}>
              <Breadcrumb.Section link>Projects</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={`/dashboard/clients/property/flat/${flatId}/request-visit/` + id}>
              <Breadcrumb.Section link>
                {project.projectName}
              </Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>Edit Project</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <Grid>
            <Grid.Column width={8} floated="left">
              {apiErrors ? (
                <Message error header="Whoops!" list={apiErrorMessage} />
              ) : null}

              {apiSuccess ? (
                <Message
                  success
                  header="Success!"
                  content={apiSuccessMessage}
                />
              ) : null}

              <Form loading={loading ? true : false}>
                {
                  user_role === 5 ? null : (
                    <>
                    <Form.Input
                      size="medium"
                      label="Project Name"
                      placeholder="Enter Project Name"
                      value={name}
                      required={true}
                      error={errors.name ? { content: errors.name } : null}
                      onChange={(e) => {
                        errors.name = null;
                        setName(e.target.value);
                      }}
                    />
                  </>
                )}
                
                {
                  user_role === 5 ? null : (
                    <>
                      <label>Client</label>
                      {project.clientId === "0" && (
                        <>
                          <Form.Input
                            size="medium"
                            placeholder="Enter Client"
                            value={clientName}
                            required={true}
                            error={
                              errors.client
                                ? { content: errors.client }
                                : null
                            }
                            onChange={(e) => {
                              errors.client = null;
                              setClientName(e.target.value);
                            }}
                          />
                        </>
                      )}

                      {project.clientId !== "0" && (
                        <>
                          <Dropdown
                            placeholder={project.clientName}
                            fluid
                            search
                            selection
                            options={clientsOptions}
                            error={errors.clientId ? { content: errors.clientId } : null}
                            onChange={(e, { value }) => {
                              errors.clientId = null;
                              setClient(value);
                            }}
                            value={client}
                          />
                        </>
                      )}
                      <br />
                  </>
                )}
                {/* <Form.Input
                  size="medium"
                  label="Client Address / Job Address"
                  placeholder="Enter Client/Job Address"
                  value={clientAddress}
                  required={true}
                  error={
                    errors.clientAddress
                      ? { content: errors.clientAddress }
                      : null
                  }
                  onChange={(e) => {
                    errors.clientAddress = null;
                    setClientAddress(e.target.value);
                  }}
                /> */}

                {
                  user_role === 5 ? null : (
                    <>
                      <label>Assigned To</label>
                      <Dropdown
                        fluid
                        multiple
                        search
                        selection
                        options={staffOptions}
                        required={true}
                        error={
                          errors.assignedToStaffsId
                            ? { content: errors.assignedToStaffsId }
                            : null
                        }
                        onChange={(e, { value }) => {
                          errors.assignedToStaffsId = null;
                          setAssignedTo(value);
                        }}
                        value={assignedTo}
                      />
                      <br />
                    </>
                )}

                <Form.TextArea
                  style={{ minHeight: 150 }}
                  label="Work Required"
                  placeholder="Enter Work Required."
                  value={description}
                  required={true}
                  error={
                    errors.description ? { content: errors.description } : null
                  }
                  onChange={(e) => {
                    errors.description = null;
                    setDescription(e.target.value);
                  }}
                ></Form.TextArea>

                {/* <label style={{ paddingBottom: 20 }}>Project Schematics</label>
                <Dropzone
                  onChange={updateFiles}
                  value={files}
                  maxFileSize={2 * 1024 * 1024}
                  maxFiles={1}
                >
                  {files.map((file) => (
                    <FileMosaic
                      key={file.id}
                      {...file}
                      onDelete={removeFile}
                      info
                      preview={true}
                    />
                  ))}
                </Dropzone> */}
                <br />
                {
                  user_role === 5 ? null : (
                  <>
                    <label>Does this project require multiple site visits?</label>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={requiresMultipleVisitOptions}
                      defaultValue={projectRequiresMultipleSiteVisits}
                      defaultSelectedLabel={projectRequiresMultipleSiteVisits}
                      error={
                        errors.requiresMultipleVisit
                          ? { content: errors.requiresMultipleVisit }
                          : null
                      }
                      onChange={(e, { value }) => {
                        errors.requiresMultipleVisit = null;
                        setprojectRequiresMultipleSiteVisits(value);
                      }}
                      value={projectRequiresMultipleSiteVisits}
                    />
                    <br />
                    {projectRequiresMultipleSiteVisits === "yes" && (
                      <>
                        <EditProjectMilestoneComponent
                          milestones={milestones}
                          onMilestonesChange={handleMilestonesChange}
                          staffOptions={staffOptions}
                        />
                      </>
                    )}

                    {projectRequiresMultipleSiteVisits === "no" && (
                      <>
                        <label style={{ paddingBottom: 20 }}>
                          Project Start Date
                        </label>
                        <DatePicker
                          dateFormat={"dd/MM/yyyy"}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          customInput={<ExampleCustomInput />}
                        />
                        <br />
                        <br />
                        <label style={{ paddingBottom: 20 }}>
                          Project End Date
                        </label>
                        <DatePicker
                          dateFormat={"dd/MM/yyyy"}
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          customInput={<ExampleCustomInput />}
                        />
                        <br />
                      </>
                    )}
                    <br /> <br />
                  </>
                )}

                {
                  user_role === 5 ? null : (
                  <>
                    <Grid style={{marginLeft: "10px"}}>
                        <label>Project addons</label>
                        <Grid.Row>
                            <Checkbox
                                label='Enable Disclaimer'
                                onChange={(e, data) => setEnableDisclaimer(data.checked)}
                                checked={enableDisclaimer}
                            />
                        </Grid.Row>

                        <Grid.Row>
                            <Checkbox
                                label='Enable Project Report'
                                onChange={(e, data) => setEnableProjectReport(data.checked)}
                                checked={enableProjectReport}
                            />
                        </Grid.Row>

                        <Grid.Row>
                            <Checkbox
                                label='Enable Landlord safety/gas record'
                                onChange={(e, data) => setEnableLandlordRecord(data.checked)}
                                checked={enableLandlordRecord}
                            />
                        </Grid.Row>

                        <Grid.Row>
                            <Checkbox
                                label='Enable Project Schematics'
                                onChange={(e, data) => setEnableProjectSchematics(data.checked)}
                                checked={enableProjectSchematics}
                            />
                        </Grid.Row>
                    </Grid>

                    <br/>
                    <br/>
                    <br />
                  </>
                )}

                <Button
                  size="midi"
                  color="primary"
                  content="Save Edit"
                  icon="cloud"
                  labelPosition="right"
                  onClick={handleSubmit}
                />
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </AdminDashLayout>
    </>
  );
};

export default EditTenantProject;
