export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phoneRegex = /^(?:\+\d{1,3})?[-\s\d]+$/;

const date = new Date();

const day = date.getDate();
const month = date.toLocaleString("en-US", { month: "short" });
const year = date.getFullYear();

const formattedDate = `${day} ${month}, ${year}`;

export const dayOptions = [

    {
        key: 1,
        text: `Monday - ${formattedDate}`,
        value: "MON",
        selected: false
    },
    {
        key: 2,
        text: `Tuesday - ${formattedDate}`,
        value: "TUES",
    },
    {
        key: 3,
        text: `Wednesday - ${formattedDate}`,
        value: "WED",
    },
    {
        key: 4,
        text: `Thursday - ${formattedDate}`,
        value: "THUR",
    },
    {
        key: 5,
        text: `Friday - ${formattedDate} `,
        value: "FRI",
    },
    {
        key: 6,
        text: `Saturday - ${formattedDate}`,
        value: "SAT",
    },
    {
        key: 7,
        text: `Sunday - ${formattedDate}`,
        value: "SUN",
    },
];

export function getDatesInWeekOfMonth(year, month, weekNumber) {
    // Initialize the month (0-indexed for JavaScript Date)
    const startDate = new Date(year, month, 1);
    const dates = [];

    // Find the first Monday of the month (or use the first day if it's Monday)
    const firstMonday = startDate.getDay() === 1 ? startDate : new Date(startDate.setDate(startDate.getDate() + (8 - startDate.getDay()) % 7));

    // Calculate the starting date of the desired week
    const weekStartDate = new Date(firstMonday);
    weekStartDate.setDate(firstMonday.getDate() + 7 * (weekNumber - 1));

    // Collect all 7 days in the specified week
    for (let i = 0; i < 7; i++) {
        const currentDay = new Date(weekStartDate);
        currentDay.setDate(weekStartDate.getDate() + i);

        // Stop if the date goes beyond the month
        if (currentDay.getMonth() !== month) break;

        dates.push(currentDay.toISOString().split('T')[0]);
    }

    return dates;
};

export const clientsOptions = [
    {
        key: 1,
        text: "Manchester United",
        value: 1,
    },
    {
        key: 1,
        text: "NHS UK",
        value: 2,
    },
];

export const requiresMultipleVisitOptions = [
    {
        key: 1,
        text: "No",
        value: "no",
    },
    {
        key: 2,
        text: "Yes",
        value: "yes",
    }
]

export const hadLunchOptions = [
    {
        key: 1,
        text: "-SELECT-",
        value: "-SELECT-",
        selected: true
    },
    {
        key: 2,
        text: "Yes",
        value: "YES",
    },
    {
        key: 3,
        text: "No",
        value: "NO",
    },
];

export const messages = [
    {
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod voluptatum nemo repellat? Ipsam delectus deserunt!",
        sender: "user",
        time: new Date(),
    },
    { text: "Hi there!", sender: "bot", time: new Date() },
    {
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quod voluptatum nemo repellat? Ipsam delectus deserunt!",
        sender: "user",
        time: new Date(),
    },
    { text: "Hi there!", sender: "bot", time: new Date() },
    { text: "Hello!", sender: "user", time: new Date() },
    { text: "Hi there!", sender: "bot", time: new Date() },
    { text: "Hello!", sender: "user", time: new Date() },
    { text: "Hi there!", sender: "bot", time: new Date() },
    // Add more messages here...
];

export const staffOptions = [
    {
        key: 1,
        text: "Alex",
        value: "002",
    },
    {
        key: 2,
        text: "Gerrad",
        value: "003",
    },
];

export const projectsData = [
    {
        id: 1,
        name: 'Wincanton',
        date: '09/09/2023',
        orderNo: '96510',
    },
    {
        id: 2,
        name: 'South Stafford',
        date: '09/09/2023',
        orderNo: '96511',
    },
    {
        id: 3,
        name: 'Great Yarmouth',
        date: '09/09/2023',
        orderNo: '96512',
    },
    // Add more project objects as needed
];

export const roleOptions = [
    {
        key: 1,
        text: "Admin",
        value: 1
    },
    {
        key: 2,
        text: "Staff",
        value: 2
    },
    {
        key: 3,
        text: "GRP: Non-Staff",
        value: 3
    },
    {
        key: 4,
        text: "GRP: Electrician",
        value: 4
    }
];

export const projectStatusOptions = [
    {
        key: 1,
        text: "Draft",
        value: "draft"
    },
    {
        key: 2,
        text: "On-going",
        value: "on-going"
    },
    {
        key: 3,
        text: "Completed",
        value: "completed"
    }
];

// Function to format a date to "YYYY-MM-DD" format
export const formatToYYYYMMDD = (dateString) => {
    const dateObj = new Date(dateString);
    if (!isNaN(dateObj.getTime())) {
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else {
        // Handle invalid date string here
        return null;
    }
}

export const formatToLongDate = (dateString) => {
    const dateObj = new Date(dateString);
    if (!isNaN(dateObj.getTime())) {
        const options = { day: "numeric", month: "long", year: "numeric" };
        const formattedDate = dateObj.toLocaleDateString("en-US", options);
        const day = dateObj.getDate();
        const suffix = (day >= 11 && day <= 13) ? "th" : (day % 10 === 1) ? "st" : (day % 10 === 2) ? "nd" : (day % 10 === 3) ? "rd" : "th";
        return `${day}${suffix} ${formattedDate.split(' ')[0]} ${formattedDate.split(' ')[2]}`;
    } else {
        // Handle invalid date string here
        return null;
    }
}


export const formatToBritishFormat = (dateString) => {
    const dateObj = new Date(dateString);
    if (!isNaN(dateObj.getTime())) {
         const year = dateObj.getFullYear();
         const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
         const day = dateObj.getDate().toString().padStart(2, '0');
        return `${day}/${month}/${year}`;
    } else {
        // Handle invalid date string here
        return null;
    }
}

export const formatRelativeTime = (time) => {
     const now = new Date();
     const diffMillis = now - new Date(time);
     const diffSeconds = Math.floor(diffMillis / 1000);
     const diffMinutes = Math.floor(diffSeconds / 60);
     const diffHours = Math.floor(diffMinutes / 60);
     const diffDays = Math.floor(diffHours / 24);
     const diffWeeks = Math.floor(diffDays / 7); // Calculate weeks
     const diffMonths = Math.floor(diffDays / 30); // Approximation for months
     const diffYears = Math.floor(diffDays / 365); // Approximation for years

    if (diffSeconds < 60) {
        return "Just now";
    } else if (diffMinutes < 60) {
        return `${diffMinutes} ${diffMinutes === 1 ? "min" : "mins"} ago`;
    } else if (diffHours < 24) {
        return `${diffHours} ${diffHours === 1 ? "hr" : "hrs"} ago`;
    } else if (diffDays < 7) {
        return `${diffDays} ${diffDays === 1 ? "day" : "days"} ago`;
    } else if (diffWeeks < 4) {
        return `${diffWeeks} ${diffWeeks === 1 ? "week" : "weeks"} ago`;
    } else if (diffMonths < 12) {
        return `${diffMonths} ${diffMonths === 1 ? "month" : "months"} ago`;
    } else {
        return `${diffYears} ${diffYears === 1 ? "year" : "years"} ago`;
    }
};

export const convertTime = (timeString) => {
     const date = new Date(timeString);
     const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return date.toLocaleTimeString('en-US', options);
}

export const parseDateStringToDate = (dateString) => {
     const dateParts = dateString.split("/");
     const year = parseInt(dateParts[2], 10);
     const month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript
     const day = parseInt(dateParts[0], 10);

    return new Date(year, month, day);
}

export const calculateHours = (startTime, endTime, hadLunch = 'NO') => {
    // Helper function to convert various date representations to Date objects
     const dateOne = new Date(startTime);

    // Calculate the number of hours
     const hoursOne = dateOne.getHours();
     const minutesOne = dateOne.getMinutes();
     const totalHoursOne = hoursOne + minutesOne / 60;

     const dateTwo = new Date(endTime);

    // Calculate the number of hours
     const hoursTwo = dateTwo.getHours();
     const minutesTwo = dateTwo.getMinutes();
     const totalHoursTwo = hoursTwo + minutesTwo / 60;

    let totalH = (totalHoursTwo - totalHoursOne)

    // Check if "hadLunch" is set to "YES," and deduct 30 minutes (0.5 hours)
    if (hadLunch === 'YES') {
        totalH -= 0.5;
    }

    return totalH;
};

export const extractFileNameAndExtension = (url) => {
    if (typeof url !== 'string') {
        return null; // or an appropriate error handling mechanism
    }
    
    // Split the URL by '/' to separate the parts
     const parts = url.split('/');
    
    // Get the last part, which should be the file name with extension
     const fileNameWithExtension = parts[parts.length - 1];
    
    // Split the file name and extension by '.' to separate them
     const [fileName, extension] = fileNameWithExtension.split('.');

    return fileName + '.' + extension;
}

export const formatM = (m) => {
    return Number(m).toLocaleString("en-GB");
};

export const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-GB", { month: "long", year: "numeric" });
}

