import React, {useState} from "react";
import {Icon, Input, Table, Button, Grid, Modal, Header} from "semantic-ui-react";
import { Link } from "react-router-dom";
import {convertTime} from "./ConvertTime";
import {toast} from "react-toastify";
import {deleteClient} from "../apiCalls";

function ClientsTable({ count, clients, onPrevPageChange, onNextPageChange, onDeleteClient }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [openModals, setOpenModals] = useState({}); // Separate state for modals

  const handleDeleteModalOpen = (clientId) => {
    setOpenModals((prevOpenModals) => ({
      ...prevOpenModals,
      [clientId]: true,
    }));
  };

  const handleDeleteModalClose = (clientId) => {
    setOpenModals((prevOpenModals) => ({
      ...prevOpenModals,
      [clientId]: false,
    }));
  };

  const filteredClients = clients.filter((client) =>
      [
        client.clientName,
        client.clientAddress,
        client.clientEmail,
        client.clientPhone,
      ].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
      <>
        <Grid>
          <Grid.Column width={8}>
            <div style={{ paddingBottom: 15 }}>
              <span style={{ fontSize: 17 }}>Displaying {count} results</span>
            </div>
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <div style={{ paddingBottom: 15, marginLeft: "20px" }}>
              <Input
                  icon="search"
                  iconPosition="left"
                  placeholder="Search clients"
                  className=""
                  size="midi"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </Grid.Column>
        </Grid>
        <Table basic="very" padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S/N</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone Number</Table.HeaderCell>
              <Table.HeaderCell>Property</Table.HeaderCell>
              <Table.HeaderCell>Projects</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredClients.map((client, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{client.clientName}</Table.Cell>
                  <Table.Cell>{client.clientAddress}</Table.Cell>
                  <Table.Cell>{client.clientEmail}</Table.Cell>
                  <Table.Cell>{client.clientPhone}</Table.Cell>
                  <Table.Cell>
                    {client.clientPropertyName ? (
                        <Link to={`/dashboard/clients/${client.id}/property/view/` + client.clientPropertyId}>
                          <Button as="a" size="mini" color="primary">
                            <b>{client.clientPropertyName}</b>
                          </Button>
                        </Link>
                    ): <p>None</p>}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/dashboard/clients/projects/' + client.id}>
                      <Button as="a" size="mini" color="primary">
                        <b>{client.totalProjects}</b>
                      </Button>
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{convertTime(client.created_at)}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/dashboard/clients/edit/${client.id}`}>
                      <Button compact size="mini" as="a" color="primary">
                        Edit
                      </Button>{" "}
                    </Link>
                    /{" "}
                    <Modal
                        dimmer={"blurring"}
                        basic={true}
                        onClose={() => handleDeleteModalClose(client.id)}
                        onOpen={() => handleDeleteModalOpen(client.id)}
                        open={openModals[client.id] || false} // Use separate state for each modal
                        size="small"
                        trigger={
                          <Button compact size="mini" as="a" color="red">
                            Delete
                          </Button>
                        }
                    >
                      <Header icon>
                        <Icon name='archive' />
                        Delete Client: {client.clientName}?
                      </Header>
                      <Modal.Content>
                        <p style={{textAlign:"center"}}>
                          Are you sure?
                        </p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button inverted={true} color="red" onClick={() => handleDeleteModalClose(client.id)}>
                          <Icon name="remove" /> No
                        </Button>
                        <Button inverted={true} color="green" onClick={ async () => {
                          handleDeleteModalClose(client.id);
                          toast('Client Successfully Deleted', {
                            type: "success"
                          });

                          onDeleteClient(client.id);

                          await deleteClient(client.id, localStorage.getItem('token'));
                        }}>
                          <Icon name="checkmark" /> Yes
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </Table.Cell>
                </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="3">
                <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                  Previous Page
                  <Icon name="left arrow" />
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="7">
                <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                  Next Page
                  <Icon name="right arrow" />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
  );
}

export default ClientsTable;
