import React from "react";
import { MdHome } from "react-icons/md";
import { PiBuildingApartmentFill } from "react-icons/pi";
import { Link, matchPath, useLocation } from "react-router-dom";

import pipekitsvg from '../assets/pipe.svg'
import AuthService from '../auth'

const ClientSideNav = ({ property }) => {
    const user = AuthService.getCurrentUser()

    const location = useLocation();
    const isActiveRoute = (pathPattern) => {
        const match = matchPath({ path: pathPattern }, location.pathname);

        return match !== null;
    };

    return (
        <>
            {
                (property !== "" && property !== null) && 
                <li
                    className={
                        // isActiveRoute("/dashboard") ||
                            // isActiveRoute("/dashboard/clients/property") ||
                            // isActiveRoute("/dashboard/clients/projects/:id") ||
                            // isActiveRoute("/dashboard/projects/view/:id") ||
                            // isActiveRoute("/dashboard/projects/view/:id/*")
                            location.pathname.includes("property")
                            ? "active"
                            : ""
                    }
                    style={{marginBottom: "30px"}}
                >

                    <Link to={`/dashboard/clients/${user.client_id}/property/view/${user.client_property_id}`}>
                        <PiBuildingApartmentFill size={30} style={{ marginBottom: "-10px" }} />
                        <span className="nav-link" style={{ marginBottom: "10px" }}>
                            {property}
                        </span>
                    </Link>
                </li>
            }

            <li
                className={
                    isActiveRoute("/dashboard") ||
                        isActiveRoute("/dashboard/clients") ||
                        isActiveRoute("/dashboard/clients/projects/:id") ||
                        isActiveRoute("/dashboard/projects/view/:id") ||
                        isActiveRoute("/dashboard/projects/view/:id/*")
                        ? "active"
                        : ""
                }>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        Projects
                    </span>
                </Link>
            </li>
           
        </>
    );
};

export default ClientSideNav;
