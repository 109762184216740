import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Table, Button, Grid, Modal, Header, TextArea, Form, Input, Icon } from "semantic-ui-react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";

import AdminDashLayout from "../components/AdminDashLayout";
import "../App.css";
import { fetchFlat } from "../apiCalls";
import sendApiRequest from "../api";

const ViewFlat = () => {
    const { flatId } = useParams();
    const [flatDetails, setFlatDetails] = useState({
        requestVisits: [],
    });
    const [tenantName, setTenantName] = useState('');
    const [tenantAddress, setTenantAddress] = useState('');
    const [tenantPhone, setTenantPhone] = useState('');
    const [tenantEmail, setTenantEmail] = useState('');
    const [lastVisit, setLastVisit] = useState('');

    const [modalOpen, setModalOpen] = useState(false);

    const [workRequired, setWorkRequired] = useState();
    const [requestedBy, setRequestedBy] = useState();
    const [date, setDate] = useState();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({email: "", password: ""});
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        fetchFlat(flatId, localStorage.getItem('token')).then(response => {
            console.log(response)
            const data = response.data;
            setTenantName(data.tenant_name);
            setTenantAddress(data.tenant_address);
            setTenantPhone(data.tenant_phone_number);
            setTenantEmail(data.tenant_email);
            setFlatDetails(response.data);
        });
    }, []);

    const today = new Date();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [deleteFlatModalOpen, setDeleteFlatModalOpen] = useState(false);

    function handleDeleteModalClose() {
        setDeleteModalOpen(false);
    }
    
    function handleDeleteModalOpen() {
        setDeleteModalOpen(true);
    }

    function handleDeleteFlatModalClose() {
        setDeleteFlatModalOpen(false);
    }
    
    function handleDeleteFlatModalOpen() {
        setDeleteFlatModalOpen(true);
    }

    // Extract the day, month, and year components
    const day = String(today.getDate()).padStart(2, '0'); // Ensure 2-digit day
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
    const year = String(today.getFullYear()).slice(2);

    const handleSubmit = () => {
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            requestedBy: requestedBy,
            projectName: `${flatDetails.flat_name} [${day}${month}${year}]`,
            propertyId: flatDetails.property_id,
            flatId: flatDetails.id,
            clientId: flatDetails.client_id, 
            workRequired: workRequired,
            scheduledTime: date,
            requestDate: new Date(),
        };

        let newErrors;
        newErrors = {};

        if (formData.workRequired !== "") {

        } else {
            newErrors.name = "Please enter the work required to be done.";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        // Login request
        (async () => {
            const postData = await sendApiRequest("post", "/tenant-projects/create", formData, localStorage.getItem('token'));
            if (postData !== null) {
                // console.log(postData);
                // any errors?
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    const errorMsg = [];

                    errorMsg.push(postData.errors);

                    setApiErrorMessage(errorMsg);
                }

                // if we got here, no errors.
                if (postData.message === "success") {
                    setApiSuccess(true);
                    setApiSuccessMessage("Request Visit created successfully");
                    toast("Request visit created successfully")
                    setModalOpen(false);
                    window.location.reload();
                }
            } else {
                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                toast("Error occurred while submitting data to the API.")
                setApiErrors(true)
                setApiSuccess(false);
                setLoading(false);
                // console.log("Error occurred while submitting data to the API.");
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const renderFlatProjectDetails = () => {
        // const logs = [1, 2, 3];
        return flatDetails.requestVisits.map((visit, index) => (
            <Table.Row key={index}>
                <Table.Cell>{new Date(visit.requestDate).toLocaleDateString('en-GB')}</Table.Cell>
                <Table.Cell>{visit.workRequired}</Table.Cell>
                <Table.Cell>{visit.assignedToUserData ? visit.assignedToUserData[0].firstname : "None"}</Table.Cell>
                <Table.Cell>{visit.requestedBy}</Table.Cell>
                <Table.Cell>
                    <Link to={`/dashboard/clients/property/flat/${visit.id}/request-visit/${visit.id}`}>
                        <Button
                            color="blue"
                        >
                            {visit.projectName}
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>
                    <Grid columns={2} padded >
                        <Grid.Column>
                            <Link to={`/dashboard/clients/property/flat/${flatId}/request-visit/edit/${visit.id}`}>
                                <Button icon>
                                    <Icon name='pencil' color="brown" />
                                </Button>
                            </Link>
                        </Grid.Column>

                        <Grid.Column>
                            <Modal
                                dimmer={"blurring"}
                                basic={true}
                                onClose={handleDeleteModalClose}
                                onOpen={handleDeleteModalOpen}
                                open={deleteModalOpen} // Use separate state for each modal
                                size="small"
                                trigger={
                                    <Button icon>
                                        <MdDelete 
                                            color="red" 
                                        />
                                    </Button>
                                }
                            >
                                <Header icon>
                                    <Icon name="archive" />
                                    Delete Project: {visit.projectName}?
                                </Header>
                                <Modal.Content>
                                    <p style={{ textAlign: "center" }}>
                                        Are you sure?
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        inverted={true}
                                        color="red"
                                        onClick={() => handleDeleteModalClose()}
                                    >
                                        <Icon name="remove" /> No
                                    </Button>

                                    <Button
                                        inverted={true}
                                        color="green"
                                        onClick={async () => {
                                            const response = await sendApiRequest(
                                                "post",
                                                "/tenant-projects/delete/" + visit.id,
                                            );

                                            if(response.message === "Project deleted successfully") {
                                                toast("Project Successfully Deleted", {
                                                    type: "success",
                                                });
                                                window.location.reload();
                                            }

                                            handleDeleteModalClose();
                                        }}
                                    >
                                        <Icon name="checkmark" /> Yes
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </Grid.Column>
                    </Grid>
                </Table.Cell>
            </Table.Row>
        ));
    };

    return (
        <AdminDashLayout>
        <div className="timesheet-container">
            <Grid style={{ marginTop: "40px" }} verticalAlign='midddle'>
            <Grid.Column mobile={16} computer={4} floated="left">
                    <Button
                        color="black"
                    >
                        {`VIEWING: ${flatDetails.flat_name ? flatDetails.flat_name.toUpperCase() : ""}`}
                    </Button>
            </Grid.Column>

            <Grid.Column mobile={16} computer={8} >
                <div>
                    <Grid>
                        <Grid.Column computer={8} >
                                    <Modal
                                        // dimmer={"blurring"}
                                        // basic={true}
                                        onClose={() => setModalOpen(false)}
                                        onOpen={() => setModalOpen(true)}
                                        open={modalOpen}
                                        size="small"
                                        trigger={
                                            <Button
                                                // color={"#C7086D"}
                                                style={{backgroundColor: "#C7086D"}}
                                            >
                                                <p style={{color: "#ffffff"}}> REQUEST VISIT </p>
                                            </Button>
                                        }
                                    >
                                        <Header>
                                            Request Visit: {flatDetails.flat_name}
                                        </Header>
                                        <Modal.Content>
                                            <Form>
                                                <Form.Field>
                                                    <label>Work Required</label>
                                                    <TextArea 
                                                        placeholder="Enter name"
                                                        value={workRequired}
                                                        onChange={(e) => {
                                                            errors.workRequired = null;
                                                            setWorkRequired(e.target.value);
                                                        }}
                                                        error={errors.workRequired ? {content: errors.workRequired} : null}
                                                    />
                                                </Form.Field>

                                                <Form.Field>
                                                    <label>Requested By</label>
                                                    <TextArea 
                                                        placeholder="Enter name"
                                                        value={requestedBy}
                                                        onChange={(e) => setRequestedBy(e.target.value)}
                                                    />
                                                </Form.Field>

                                                <Form.Field>
                                                    <label>Scheduled Time</label>
                                                    <Input
                                                        type="date"
                                                        value={date}
                                                        onChange={(e) => setDate(e.target.value)}

                                                    />
                                                </Form.Field>
                                            </Form>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button
                                                color="youtube"
                                                onClick={() => {
                                                    setModalOpen(false)
                                                }}
                                            >
                                                Close
                                            </Button>

                                            <Button inverted={true} color="green" onClick={handleSubmit}>
                                                Submit
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                        </Grid.Column>

                        <Grid.Column computer={8} >
                            <Link to={`/dashboard/clients/property/${flatDetails.property_id}/flat/edit/${flatDetails.id}`}>
                                    <Button
                                        style={{backgroundColor: "#FFE203"}}
                                    >
                                        <p style={{color: "#000000"}}> EDIT FLAT DETAILS </p>
                                    </Button>
                            </Link>
                        </Grid.Column>

                        <Grid.Column computer={8}>
                            <Modal
                                dimmer={"blurring"}
                                basic={true}
                                onClose={handleDeleteFlatModalClose}
                                onOpen={handleDeleteFlatModalOpen}
                                open={deleteFlatModalOpen} // Use separate state for each modal
                                size="small"
                                trigger={
                                    // <Button icon>
                                    //     <MdDelete 
                                    //         color="red" 
                                    //     />
                                    // </Button>
                                    <Button
                                        color="red"
                                        content="DELETE FLAT"
                                        icon="trash"
                                        labelPosition="right"
                                    />
                                }
                            >
                                <Header icon>
                                    <Icon name="archive" />
                                    Delete Flat: {flatDetails.flat_name ? flatDetails.flat_name : ""}?
                                </Header>
                                <Modal.Content>
                                    <p style={{ textAlign: "center" }}>
                                        Are you sure?
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        inverted={true}
                                        color="red"
                                        onClick={() => handleDeleteFlatModalClose()}
                                    >
                                        <Icon name="remove" /> No
                                    </Button>

                                    <Button
                                        inverted={true}
                                        color="green"
                                        onClick={async () => {
                                            const response = await sendApiRequest(
                                                "post",
                                                "/clients/property/flat/delete/" + flatDetails.id ?? "",
                                            );

                                            if(response.message === "Flat deleted successfully") {
                                                toast("Flat Successfully Deleted", {
                                                    type: "success",
                                                });
                                                navigate(`/dashboard/clients/${flatDetails.client_id}/property/view/${flatDetails.property_id}`);
                                            }

                                            handleDeleteFlatModalClose();
                                        }}
                                    >
                                        <Icon name="checkmark" /> Yes
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </Grid.Column>
                    </Grid>
                </div>
            </Grid.Column>
            </Grid>
        </div>

        <br />
        
        {/* Tenant details */}
        <div>
            <h3>Tenant Name: {tenantName}</h3>
            <h3>Tenant Address: {tenantAddress}</h3>
            <h3>Phone Number: {tenantPhone}</h3>
            <h3>Email Address: {tenantEmail}</h3>
            {flatDetails.lastVisit ? (
                <h2 style={{color: "#0c94be"}}>Last Visit: {new Date(flatDetails.lastVisit).toLocaleDateString("en-Gb")}</h2>
            ): ""}
        </div>

        <br />

        {/* Property flat tables. */}
        <Table celled className="custom-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>REQUEST DATE</Table.HeaderCell>
                    <Table.HeaderCell>WORK REQUESTED</Table.HeaderCell>
                    <Table.HeaderCell>PERFORMED BY</Table.HeaderCell>
                    <Table.HeaderCell>REQUESTED BY</Table.HeaderCell>
                    <Table.HeaderCell>LINKED PROJECTS</Table.HeaderCell>
                    <Table.HeaderCell>ACTIONS</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {renderFlatProjectDetails()}
            </Table.Body>
        </Table>
      </AdminDashLayout>
    );
};

export default ViewFlat;